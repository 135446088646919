
@import '~@/css/variables';
@import '~@/css/mixins';
@import '~@/css/grid';

.result-box {
  text-align: center;
  .box-img {
    margin-bottom: 30px;
    img {
      width: 164px;
    }
  }
  .box-title {
    font-size: 22px;
    font-weight: bold;
    color: $color-primary;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
  }
  .box-des {
    color: $color-font;
  }
  margin-bottom: 40px;
}
