/* ===============================
              Colors
=============================== */
$color-primary:#008E4B;
$color-sec: #ABCD03;
$color-trd: #CDC990;
$color-danger: #ff5970;
$color-red: #DF4710;
$color-pure: #FFFFFF;
$color-feature: #0498D8;
$color-tip: #2374AD;

$color-title: #333333;
$color-font: #030303;

$color-blue: #2374AD;

// 文字顏色
$color-font: #231815;
$color-font-gray: #898989;

// 邊框
$color-border: #CDC990;

// linear gradient
$color-feature-btn-off-linear-gradient: linear-gradient(180deg, #FCFCFC, #E8EAEF, #D9DBDE);
$color-feature-btn-on-linear-gradient: linear-gradient(180deg, #A1A6B2, #EFF1F4);

// shadow
$color-feature-btn-off-shadow: 0px 5px 5px 0 rgba(#363A4D, .6), 8px 9px 0px 0px rgba(#363A4D, .05), -3px 4px 2px 4px rgba($color-pure, 0.3);


/* ===============================
              Fonts
=============================== */
// family
$ff-open-sans: 'Open Sans', '微軟正黑體', '蘋果儷中黑', Arial, Helvetica, sans-serif;
$ff-nunito: 'Nunito', '微軟正黑體', '蘋果儷中黑', Arial, Helvetica, sans-serif;
$ff-note: 'Nunito', "微軟正黑體", "Microsoft JhengHei", sans-serif;
$ff-msjh: "微軟正黑體", "Microsoft JhengHei";

// size
$fz-h2: 2rem; // 32px
$fz-h3: 1.5rem; // 24px
$fz-h4: 1.0625rem; // 17px
$fz-h5: 0.9375rem; // 15px
$fz-h6: 0.875rem; // 14px
$fz-mini: 0.75rem; // 12px
