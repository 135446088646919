
@import '~@/css/variables';
@import '~@/css/mixins';
@import '~@/css/grid';

.result-box {
  text-align: center;
  .box-img {
    position: relative;
    margin-bottom: 30px;
    img {
      width: 164px;
    }
    .box-circle {
      position: absolute;
      right: 50%;
      top: 50%;
      transform: translate(calc(50% + 100px), calc(-50% + 30px));
      border: 1px solid red;
      color: red;
      font-size: 28px;
      aspect-ratio: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      padding: 5px;
    }
  }
  .box-title {
    font-size: 22px;
    font-weight: bold;
    color: $color-primary;
    letter-spacing: 0.1em;
    margin-bottom: 10px;
  }
  .box-des {
    color: $color-font;
  }
  margin-bottom: 40px;
}
