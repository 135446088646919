@import "~@/css/variables";
@import "~@/css/mixins";
@import "~@/css/grid";
@import "~@/css/utils";
@import 'icons.css';
@import 'icomoon/style.css';

/* Custom color theme properties */
.ios,
.md {
    // 修改預設值
    --f7-font-family: 'Nunito', "微軟正黑體", "Microsoft JhengHei", sans-serif;
    
    --f7-text-color: #{$color-font};
    --f7-link-pressed-opacity: 0.3;

    --f7-page-bg-color: #fff;

    --f7-navbar-height: 32px;
    --f7-toolbar-height: 64px;

    --f7-input-font-size: 16px;
    --f7-input-placeholder-color: #515357;

    --f7-button-text-transform: none;
    --f7-button-font-weight: normal;

    --f7-sheet-bg-color: #F0F2F7;

    --f7-fab-size: 54px;

    --f7-navbar-link-color: #{$color-primary};
    --f7-panel-backdrop-bg-color: rgba(0, 0, 0, 0.6);
    // 修改預設值 /

    --color-primary: #{$color-primary};
    --base-bg: url(~@/assets/images/general/bg.png);
    --navbar-bg: url(~@/assets/images/general/bg.png);

    @include mobile-down {
        --f7-input-font-size: 14px;
    }

    --f7-theme-color: #008e4b;
    --f7-progressbar-height: 6px;
 
}

/* Your app custom styles here */

// ==== 基礎 ====
.ios,
.md {
    body {
        margin: 0 auto;
    }

    // common
    img {
        max-width: 100%;
        max-height: 100%;
    }

    .date {
        display: flex;
        align-items: center;
        font-size: $fz-mini;
        color: $color-tip;

        img {
            margin-right: 0.4375rem;
        }
    }

    .text-editor-block {
        table {
            max-width: 100%;
        }
    }
}

[v-cloak] {
    display: none;
}

// ==== 基礎 / ====

//==== 模組 ====

// swiper
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    // disable後仍然要可以點的到
    pointer-events: all;
}

// swiper /

// ==== 模組 ==== /


// ==== 內建擴充 ====
.ios,
.md {

    body.is-scrolling {
        .navbar {
            // box-shadow: 0 3px 10px rgba(#000, 0.16);
        }

        .navbar .navbar-bg {
            opacity: 1;
        }

        .popup {
            // .navbar{
            //     background: #fbfbfb;
            // }
        }
    }

    body:not(.is-scrolling) {
        .page.no-page-top-shadow-if-no-scroll {
            &:before {
                opacity: 0;
            }
        }
    }

    .disabled {
        opacity: 0.35 !important;
    }

    // Navbar
    body:not(.is-scrolling) {

        // nav-no-bg-when-no-scroll
        .navbar.navbar-no-bg-when-no-scroll {
            .navbar-bg {
                opacity: 0;
            }
        }
    }

    body.is-scrolling {
        .navbar.navbar-bg-primary-on-scroll {
            .navbar-bg {
                --navbar-bg: #{$color-primary};
            }
        }
    }

    .navbars {}

    .navbar {

        // height: 4.125rem;
        // box-shadow: 0px 3px 10px rgba(#000, 0.16);
        &.nav-color-w {
            --f7-navbar-link-color: #fff;
            color: #fff;
        }

        &.bg-transparent {
            background: transparent;

            .navbar-bg {
                background: transparent;
            }
        }

        &.navbar-pd-24px {
            .navbar-inner {
                padding: 0 24px;
            }
        }

        &.navbar-sm-pd {
            .navbar-inner {
                padding: 0 10px;
            }
        }

        &.navbar-no-bg {
            .navbar-bg {
                opacity: 0;
            }
        }

        &.navbar-no-bg {
            .navbar-bg {
                opacity: 0;
            }
        }

        &.navbar-no-bg-always {
            box-shadow: none;

            .navbar-bg {
                display: none;
            }
        }

        &.navbar-bg-primary {
            background: $color-primary;
        }

        &.navbar-bg-sky {
            .navbar-bg {
                background: #A2F1FF;
            }
        }

        &.navbar-no-shadow {
            box-shadow: none;
        }

        &.navbar-inset-20px {
            .navbar-inner {
                padding: 0 20px;
            }
        }

        .navbar-inner {
            padding: 0 15px;

            .right {
                justify-content: flex-end;
            }
        }

        .navbar-bg {
            background: var(--navbar-bg);
        }

        .left {
            .icon-chevron-left {
                font-size: 1.875rem;
                font-weight: 600;
                background: -webkit-linear-gradient(180deg, #9db2db, #1b70aa);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }

        .title {
            font-family: $ff-nunito;
            font-size: 1.2857rem;
            letter-spacing: 0.1em;
            font-family: $ff-nunito;
            font-weight: bold;
            @include flex(center, center);

            img {
                height: calc(30 / 14 * 1rem);
            }
        }

        .right {
            img {
                height: 1.875rem;
            }
        }

        .link {
            height: auto;
            padding: 0 3px;
        }

        .f7-nav-title-txt {
            display: inline-block;
        }

        .nav-back-btn {
            width: 36px;
            height: 36px;
            background: rgba(#000000, 0.2);
            color: #fff;
            border-radius: 50%;
            @include flex(center, center);
            font-size: 26px;
        }

        .nav-back-angle {
            width: 23px;
        }

        // icon
        i {
            font-size: 20px;
        }

        .icon-mail {
            font-size: 17.6px;
        }

        .icon-menu_hamgurg {
            font-size: 16px;
        }

        .fa-angle-left {
            font-size: 26px;
        }

        .icon-trash {
            font-size: 20px;
        }

        .icon-text-file {
            font-size: 20px;
        }

        // icon /

        // 其他
        .navbar-select-item {
            display: flex;
            // max-width: 71%;
            max-width: 90%;
            min-width: 120px;
            color: $color-font;
            font-size: 18px;
            letter-spacing: 0.1em;
            font-weight: bold;

            .item-content {
                min-width: 0;
                @include text-ellipsis();
            }

            .item-append {
                flex-shrink: 0;
            }
        }

        .icon-cart1 {
            .muki-num-badge {
                top: -7px;
                right: -7px;
            }
        }

        // 其他

        .toolbar-bottom {
            .toolbar-inner {
                .col-item {
                    a.menu-link {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    // navbar
    .navbar-inner {
        justify-content: space-between;

        .left,
        .right {
            min-width: 2.75rem;

            .link {
                min-width: 28px;
                @include flex(center, center);
                padding: 0;
            }
        }

        .right {
            margin-left: 0.625rem;
        }
    }

    // navbar /

    // Page
    .page {
        background-image: var(--base-bg);
        // background-size: cover;
        // background-position: center center;

        &.no-toolbar {
            &:not(.has-fixed-bottom-toolbar) {
                --f7-page-toolbar-bottom-offset: 0;
            }
        }

        // 無上 shadow 永遠
        &.no-page-top-shadow-always {
            &:before {
                opacity: 0;
            }
        }

        &.sty-navbar-no-shadow {
            &:before {
                display: none;
            }
        }

        &.sty-navbar-overlap {
            .page-content {
                --f7-page-navbar-offset: 0;
            }
        }

        &.sty-toolbar-veg-deco {
            --f7-page-content-extra-padding-bottom: 40px;

            .page-content {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: fixed;
                    bottom: var(--f7-page-toolbar-bottom-offset);
                    left: 0;
                    right: 0;
                    height: 40px;
                    background: url('~@/assets/images/general/vegetable.svg');
                    background-repeat: repeat-x;
                    z-index: 1;
                }
            }
        }

        &.sty-toolbar-veg-deco2 {
            .page-content {
                display: flex;
                flex-direction: column;

                &:after {
                    content: '';
                    display: block;
                    position: relative;
                    height: 40px;
                    background: url('~@/assets/images/general/vegetable.svg');
                    background-repeat: repeat-x;
                    z-index: 1;
                    flex-shrink: 0;
                    margin-top: auto;
                }
            }
        }

        &.sty-page-bottom-veg-deco {
            .page-content {
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: fixed;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 40px;
                    background: url('~@/assets/images/general/vegetable.png');
                    background-repeat: repeat-x;
                    z-index: 1;
                }
            }
        }

        &.sty-page-bottom-veg-deco2 {
            >.page-content {
                display: flex;
                flex-direction: column;

                &:after {
                    content: '';
                    display: block;
                    position: relative;
                    height: 40px;
                    background: url('~@/assets/images/general/vegetable.svg');
                    background-repeat: repeat-x;
                    z-index: 1;
                    flex-shrink: 0;
                    margin-top: auto;
                }
            }

            .content-scroller:not(.content-scroller_fill-height) {
                min-height: auto;
                max-height: calc(100% - 40px);
            }

            .page-content.infinite-scroll-content {
                .scroller-body {
                    padding-bottom: 40px;
                }

                .infinite-scroll-preloader {
                    padding-bottom: 40px;
                }
            }
        }

        // navbar shadow 會看到前一頁的陰影
        // 使用此替代方案
        &:not(.no-navbar):not(.sty-navbar-no-shadow) {
            &::before {
                content: '';
                display: block;
                // height: calc(var(--f7-page-navbar-offset, 0px) + var(--f7-page-toolbar-top-offset, 0px) + var(--f7-page-subnavbar-offset, 0px) + var(--f7-page-searchbar-offset, 0px) + var(--f7-page-content-extra-padding-top, 0px));
                height: calc(var(--f7-navbar-height) + var(--f7-safe-area-top));
                position: absolute;
                left: 0;
                right: 0;
                // top 直接複製 f7 樣式
                top: 0;
                box-shadow: 0px 3px 10px rgba(#000, 0.16);
                z-index: 5;
                pointer-events: none;
            }
        }
    }

    // Page /



    // toolbar-menu
    .toolbar-menu {
        background: url('~@/assets/images/general/footerbg.png');
        height: auto;
        min-height: 64px;
        .toolbar-inner {
            padding: 0;
            position: relative; 
            display: flex;
            justify-content: center;
            align-items: center;
            .row {
                display: flex;
                align-items: center;
            }
        }
    }

    // toolbar-menu
    .toolbar-order-menu {
        background-image: var(--base-bg);
        .toolbar  {
            margin-bottom: 0;
        }
        .toolbar-inner {
           padding: 0px 15px;
        }
    }

    // toolbar-menu /

    // list
    .list {

        ul {
            background: none;

            &:before {
                display: none;
            }

            &:after {
                display: none;
            }


        }

        li {
            &.is-unread {
                &:before {
                    background-color: $color-sec;
                    border-radius: 50%;
                    content: "";
                    height: .525rem;
                    width: .525rem;
                    left: 6px;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        &.v-gape-16px {
            ul {
                >li {
                    padding-top: 16px;
                    padding-bottom: 16px;
                }
            }
        }

        &.v-gape-18px {
            ul {
                >li {
                    padding-top: 18px;
                    padding-bottom: 18px;
                }
            }
        }

        &.top-line {
            ul {
                >li:first-child {
                    border-top: 1px solid rgba(#000, 0.12);
                }
            }
        }

        &.tail-line {
            ul {
                >li:last-child {
                    border-bottom: 1px solid rgba(#000, 0.12);
                }
            }
        }

        &.sty-lined {
            ul {
                >li {
                    border-bottom: 1px solid rgba(#000, 0.12);
                }
            }
        }

        &.sty-inset {
            ul> {
                li {
                    padding-left: 30px;
                    padding-right: 30px;
                }
            }
        }

        &.sty-inset-20px {
            ul> {
                li {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
        }

        @include mobile-down {
            &.sty-inset {
                ul> {
                    li {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }

            &.sty-inset-20px {
                ul> {
                    li {
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }

    // list /

}

// ==== 內建擴充  / ====

// ==== 指令 ====
.ios,
.md {
    .bg-sec {
        background: $color-sec !important;
    }

    .bg-light {
        background: #FBFBFB;
    }

    .bg-dull {
        background: #F0F2F7;
    }

    .bg-danger {
        background: $color-danger !important;
    }

    // bg

    // radius
    .radius-5px {
        border-radius: 5px;
    }

    .radius-8px {
        border-radius: 8px;
    }

    .radius-10px {
        border-radius: 10px;
    }

    // radius /

    // text
    .text-theme {
        color: $color-primary;
    }

    // text /
}

// ==== 指令 / ====

// ==== 元件 ====
.ios,
.md {
    .page-inner {
        padding: 0 15px;
    }

    .page-inner-20px {
        padding-left: 20px;
        padding-right: 20px;
    }

    .page-inner-24px {
        padding-left: 24px;
        padding-right: 24px;
    }

    .page-inner-30px {
        padding-left: 30px;
        padding-right: 30px;
    }

    .page-inner-17p5px {
        padding: 0 17.5px;
    }



    // form
    form.form-list {
        margin-top: 0;
    }

    .list-v-gap-8px {
        .base-input {
            margin-bottom: 8px;
        }
    }

    .list-v-gap-35px {
        .base-input {
            margin-bottom: 35px;
        }

        @include mobile-down {
            .base-input {
                margin-bottom: 25px;
            }
        }
    }

    // form /

    // btn
    .button {
        font-size: 16px;

        &.size-h-45px {
            height: 45px;
        }

        &.size-h-40px {
            height: 40px;
        }

        &.size-h-35px {
            height: 35px;
        }

        &.shadow {
            box-shadow: 0 10px 28px #00000029;
        }

        &.active-state {
            opacity: .7;
        }

        &.btn-radius-10px {
            border-radius: 10px;
        }

        &.btn-radius-pill {
            border-radius: 20px;
        }

        &.btn-lt-space {
            letter-spacing: 0.1em;
        }

        @include mobile-down {
            font-size: 15px;

            &.size-h-40px {
                height: 35px;
            }

            &.size-h-45px {
                height: 40px;
            }
        }
    }

    .btn-pill {
        border-radius: 22px;
    }

    .btn-theme {
        background: $color-primary;
        color: #fff;
    }

    .btn-sec {
        background: $color-sec;
        color: #fff;
    }

    .btn-blue {
        background: #0498D8;
        color: #fff;
    }

    .btn-gt-theme {
        background: linear-gradient(-270deg, #9DB2DB, #1B70AA);
        color: #fff;
    }

    .btn-gt-blue-light {
        background: linear-gradient(-93deg, #0498D8 48%, #A1E3FF);
        color: #fff;
    }

    .btn-outline-theme {
        color: $color-primary;
        background: none;
        border: 1px solid $color-primary;

        &.active-state {
            background: $color-primary;
            color: #fff;
        }
    }

    .btn-outline-sec {
        color: $color-sec;
        background: none;
        border: 1px solid $color-sec;

        &.active-state {
            background: $color-sec;
            color: #fff;
        }
    }

    .btn-outline-light {
        color: $color-font;
        background: none;
        border: 1px solid #CDC990;

        &.active-state {
            background: #CDC990;
            color: #fff;
        }
    }

    .btn-outline-light2 {
        color: #CDC990;
        background: none;
        border: 1px solid #CDC990;

        &.active-state {
            background: #CDC990;
            color: #fff;
        }
    }

    .btn-outline-gray {
        color: #898989;
        background: none;
        border: 1px solid #CCCCCC;

        &.active-state {
            background: #CCCCCC;
            color: #fff;
        }
    }

    // btn /

    // badge
    .badge-primary {
        background: $color-primary;
        color: #fff;
    }

    .badge-outline-primary {
        background: none;
        border: 1px solid $color-primary;
        color: $color-primary;
    }

    // badge/

    // pill-2pend-btn
    .pill-2pend-btn {
        display: inline-flex;
        align-items: stretch;
        color: $color-primary;
        font-size: 0.85714rem;
        border: 1px solid currentColor;
        border-radius: 15px;
        overflow: hidden;

        .btn-prepend {
            @include flex(center, center);
            margin-left: -1px;
            border-radius: inherit;
            flex-shrink: 0;
            background: $color-primary;
            color: #fff;
            padding: 7px 10px;
            min-width: 24px;
            font-size: 16px;
        }

        .btn-main {
            padding: 6px 10px;
            @include flex(center, center);
        }
    }

    // pill-2pend-btn /

    // bed
    .colored-bed {
        background: #EEF5CC;

        .bed-inner {
            padding: 0 15px;
        }
    }

    .simple-bed {
        border-radius: 15px;
        margin-bottom: 1.07rem;
        padding: 20px;
        background: #FFFFFF;
        border: 2px solid #EEF5CC;
        box-shadow: 5px 5px 8px #00000029;

        &.sty-more-area {
            padding: 15px;
            border-radius: 10px;
        }

        &.sty-form {
            padding: 0 15px;
            border-radius: 10px;
        }

        @include mobile-down {
            padding: 15px;

            &.sty-more-area {
                padding: 10px;
            }

            &.sty-form {
                padding: 0 10px;
            }
        }
    }

    // bed /

    // icon-info-item
    .info-icon-item {
        display: inline-flex;
        align-items: center;

        img {
            display: block;
        }

        .item-prepend {
            margin-right: 3px;
        }
    }

    // icon-info-item /

    // section-head
    .section-head {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        .section-title {
            margin-bottom: 0;
            font-size: 1.2857rem;
            color: $color-font;
            letter-spacing: 0;
            font-weight: bold;

            &:before {
                display: none;
            }

            .title-icon {
                margin-right: 5px;
                margin-bottom: 3px;
                max-width: 16px;
            }
        }

        .head-col {
            flex-grow: 1;
            min-width: 0;

            &:first-child {
                text-align: left;
            }

            &:last-child {
                text-align: right;
            }
        }
    }

    // section-head /


    // more-btn
    .more-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: rgba(#231815, 0.3);
        border-radius: 10px;
        padding: 2px 8px;
        color: #fff;
        font-size: 0.85714rem;
        font-family: $ff-msjh;
    }

    // more-btn /



    // logo-area
    .logo-area {
        position: relative;
        margin-bottom: 25px;
        max-height: 35.7142857vh;
        overflow: hidden;

        .area-main-img {
            max-height: none;
            width: 100%;
        }

        .area-wave {
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            transform: translateY(15px);
        }

        .area-logo {
            width: 185px;
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
        }

        @include mobile-down {
            margin-bottom: 15px;
            overflow: hidden;
            max-height: 24vh;
        }
    }

    // logo-area /

    // section-lg-title
    .section-lg-title {
        color: $color-primary;
        font-size: calc(24 / 14 * 1rem);
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        margin: 0;
        margin-bottom: 1.25em;

        &:before {
            content: '';
            display: block;
            width: 4px;
            height: 1.0833em;
            margin-right: 0.625em;
            background: linear-gradient(to bottom, $color-primary 0 50%, $color-sec 50.1%);
        }
    }

    // section-lg-title /

    // section-title
    .section-title {
        color: $color-primary;
        font-size: 1.42857rem;
        letter-spacing: 0.05em;
        display: flex;
        align-items: center;
        margin: 0;
        margin-bottom: 1.0714em;
        font-weight: bold;

        &:before {
            content: '';
            display: block;
            width: 4px;
            height: calc(20 / 16 * 1em);
            margin-top: calc(((20 / 16) - 1) * 0.5 * -1em);
            margin-right: 0.5em;
            background: linear-gradient(to bottom, $color-primary 0 50%, $color-sec 50.1%);
        }

        &.star-after {
            &:after {
                content: '*';
                color: $color-danger;
                position: relative;
                top: -1px;
            }
        }

        &.sty-2side {
            .title-inner {
                flex-grow: 1;
                display: flex;
                justify-content: space-between;
            }
        }

        i {
            font-size: 16px;
            color: $color-primary;
        }
    }

    // section--title /

    // section-description
    .section-description {
        color: #898989;
    }

    // section-description /

    // store-card
    .store-card {
        font-size: 1.14285rem;

        .card-title {
            display: flex;
            align-items: center;
            font-size: 1.25em;
            margin-bottom: 10px;
            color: $color-primary;
            font-weight: bold;

            &:before {
                content: '';
                display: block;
                width: 4px;
                height: 1.0833em;
                margin-top: -0.04165em;
                margin-right: 0.625em;
                background: linear-gradient(to bottom, $color-primary 0 50%, $color-sec 50.1%);
            }
        }

        .card-info-item {
            font-size: 1em;
            display: flex;
            align-items: center;

            i {
                font-size: 16.5px;
                margin-top: 4px;
                display: inline-block;
            }

            .icon-pin {
                font-size: 1em
            }

            .icon-wall-clock {
                font-size: calc(14 / 16 * 1em);
            }

            .item-prepend {
                color: #898989;
                margin-right: 5px;
            }

            .item-append {
                margin-left: 5px;
            }

            +.card-info-item {
                padding-top: 3px;
            }
        }

        .card-body {
            margin-bottom: 10px;
        }

        .card-footer {
            --f7-grid-gap: 10.5px;
        }

        .card-foot {
            font-size: 0.95em;
        }

        .card-tag {
            border: 1px solid #EEF5CC;
            background: #EEF5CC;
            color: $color-primary;
            text-align: center;
            padding: 4px;

            &.outline {
                border-color: #C5C5C5;
                color: #898989;
                background: none;
            }
        }

        @include mobile-down {
            font-size: 1.04285em;
        }
    }

    // store-card /

    // v-lined-list
    .v-lined-list {

        &.sty-go-arrow {
            li {
                padding-right: 25px;
                position: relative;

                &:before {
                    content: '';
                    display: block;
                    @include icomoon('\e920');
                    font-size: 12px;
                    color: $color-trd;
                    position: absolute;
                    top: 50%;
                    right: 9px;
                    transform: translateY(-50%);
                    line-height: 1;
                }
            }
        }

        &.no-tail-line {
            li {
                &:last-child {
                    border-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }

        &.v-lined-list-gap-20px {
            li {
                padding-bottom: 10px;

                +li {
                    padding-top: 10px;
                }
            }
        }

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
        }

        li {
            border-bottom: 1px solid #CDC990;
            padding-bottom: 15px;

            +li {
                padding-top: 15px;
            }
        }
    }

    // v-lined-list /

    // theme-wave-deco-box
    .theme-wave-deco-box {
        position: relative;
        background: linear-gradient(180deg, #008E4B, #5FB122);

        &.sty-overlap-page {
            padding-top: calc(var(--f7-navbar-height) + var(--f7-safe-area-top) + 10px);
            padding-bottom: 56px;
        }

        .box-deco {
            background: url('~@/assets/images/general/member_info_box_deco.svg');
            background-repeat: repeat-x;
            height: 56px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }

        .box-wave {
            background: url('~@/assets/images/general/wave.png');
            min-width: 100%;
            background-repeat: repeat-x;
            height: 16.06px;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
        }

        .green-wave {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            min-width: 100%;
        }

        .box-content {
            position: relative;
            z-index: 2;
        }
    }

    // theme-wave-deco-box /

    // member-info-box
    .member-info-box {
        color: #fff;

        .box-upper {
            display: flex;
            align-items: center;
            margin-bottom: 2px;

            .box-main {
                flex-grow: 1;
            }
        }

        .muki-price-displayer {
            font-size: 30px;
        }

        .box-qrcode {
            width: calc(85 / 12 * 1em);
            color: #EEF5CC;
            padding: 7px 0;
            font-size: calc(12 / 14 * 1rem);
            text-align: center;
            position: relative;

            &:before {
                content: '';
                display: block;
                @include absolute(0, 3px, 0, 3px);
                border: 1px solid #EEF5CC;
                border-radius: 10px;
            }

            i,
            img {
                margin-bottom: 3px;
            }

            img {
                display: block;
                margin: 0 auto;
                margin-bottom: 3px;
            }

            .desc-txt {
                display: block;
                backface-visibility: hidden;
                transform: perspective(1px) scale(calc(9 / 12));
                transform-origin: center top;
                font-weight: lighter;
                letter-spacing: -0.2px;
                margin-bottom: -5px;
                font-weight: bold;
                // text-rendering: geometricPrecision;
            }
        }
    }

    // member-info-box /


    // link-bar
    .link-bar {
        display: flex;
        align-items: center;
        font-size: 1rem;

        .bar-prepend {
            flex-shrink: 0;
            margin-right: 10px;
            width: calc(22 / 14 * 1em);

            img {
                display: block;
            }
        }

        .bar-main {
            font-weight: bold;
            font-size: 1em;
            letter-spacing: 0.1em;
        }
    }

    // link-bar /

    // muki-simple-list
    .muki-simple-list {
        margin-bottom: 20px;

        ul {
            padding: 0;
            list-style-type: none;
            margin: 0;
        }

        li {
            +li {
                padding-top: 10px;
            }
        }
    }

    // muki-simple-list /


    // custom-control-bar
    .custom-control-bar {
        display: flex;
        align-items: center;

        .bar-prepend {
            flex-shrink: 0;
        }

        .bar-main {
            flex-grow: 1;
            min-width: 0;
        }
    }

    // custom-control-bar /

    // checkout-table
    .checkout-table {
        table {
            width: 100%;
        }

        th {
            color: #898989;
            text-align: left;
        }

        td {
            color: $color-font;
            text-align: right;
        }

        .table-price {
            font-size: 16px;
            font-weight: bold;
        }

        .table-h-line {

            td,
            th {
                &:before {
                    content: '';
                    display: block;
                    height: 1px;
                    background: #CDC990;
                    margin: 5px 0;
                }
            }
        }

        .table-total-bar {

            th,
            td {
                color: $color-font;
                font-weight: bold;
            }
        }

        .muki-price-displayer {
            font-size: 1.142857rem;

            img {
                max-width: calc(20 / 16 * 1em);
            }
        }
    }

    // checkout-table /

    // cart-product-card
    .cart-checkout-list {
        li {
            padding-bottom: 10px;
        }
    }

    .cart-product-card {
        display: flex;
        align-items: flex-start;

        .card-media {
            width: 25.73%;
            max-width: 140px;
            margin-right: 10px;
            flex-shrink: 0;
            position: relative;

            &:before {
                content: '';
                display: block;
                padding-top: 100%;
            }

            img {
                max-height: 100%;
                @include absolute(0, 0, 0, 0);
                margin: auto;
            }
        }

        .card-main {
            flex-grow: 1;

            .card-title {
                font-weight: bold;
                margin-bottom: 5px;
            }

            .card-des {
                color: $color-font-gray;
                margin-bottom: 6px;
                font-size: calc(12 / 14 * 1rem);
            }

            .card-amount {
                color: $color-font-gray;
            }

            .number-toggler {
                width: 102px;
                max-width: 100%;

                .toggler-btn {
                    width: 26px;
                }
            }
        }

        .card-tool {
            flex-shrink: 0;
            margin-left: 7px;
        }

        .card-delete {
            font-size: 18px;
            color: #C5C5C5;
        }

        .card-info-bar {
            display: flex;
            align-items: center;
            justify-content: space-between;

            +.card-info-bar {
                padding-top: 7px;
            }

            .card-amount {
                margin-right: 10px;
            }

            .muki-price-displayer {
                margin-bottom: 0;
                // .displayer-inner{
                //     margin-bottom: 0;
                // }
                // .displayer-item, .displayer-plus{
                //     margin-bottom: 0;
                // }
            }

            .card-pickup-date {}
        }

        .card-tag {
            display: inline-block;
            border-radius: 12px;
            padding: 2px 8px;
            font-size: calc(12 / 16 * 1em);
        }

        .card-red-tag {
            background: #F9DACF;
            color: #DF4710;
        }

        .muki-price-displayer {
            margin-bottom: 10px;
            font-size: 16px;

            img {
                max-width: calc(20 / 16 * 1em);
            }
        }

        @include mobile-down {
            .muki-price-displayer {
                font-size: 14px;
            }
        }
    }

    // cart-product-card /

    // v-lined-table
    .v-lined-table {
        border-collapse: collapse;

        tr {
            +tr {

                td,
                th {
                    border-top: 1px solid #CDC990;
                }
            }
        }

        th {
            padding-right: 7px;
        }

        td,
        th {
            padding-top: 12px;
            padding-bottom: 12px;
            box-sizing: border-box;
        }
    }

    // v-lined-table /

    // pickup-store-box
    .pickup-store-box {
        position: relative;
        padding-right: 20px;

        &.sty-no-go-icon {
            padding-right: 0;

            &:after {
                display: none;
            }
        }

        &:after {
            content: '';
            @include icomoon('\e920');
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 12px;
            color: #CDC990;
        }

        .box-store-name {
            color: $color-primary;
            margin-bottom: 5px;
        }

        .box-store-address {
            display: flex;

            .address-prepend {
                font-size: 16px;
                margin-right: 6px;
            }
        }
    }

    // pickup-store-box /


    .sheet-modal .toolbar{
        background: #EEF5CC;
        backdrop-filter: none;
        border-radius: 10px 10px 0 0;
    }
    .sheet-modal {
        --f7-toolbar-height: 40px;
        --f7-picker-column-font-size: 18px;
        background: #fff;
        box-shadow: 0 -5px 10px #00000029;
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        &:before{
            display: none;
        }
        .link{
            color:$color-primary;
            font-weight: bold;
            font-size: 18px;
        }
        .sheet-modal-inner{
            -webkit-mask-box-image: none;
            background: none;
        }
        .picker-column{
            z-index: 2;
        }
        .picker-item{
            span{
            }
        }
        .picker-item-selected{
            color:#fff;
            // font-size: 22px;
            span{
                transform: scale(calc( 22 / 18));
                transform-origin: center;
                display: inline-block;
            }
        }
        .picker-center-highlight{
            z-index: 1;
            background: $color-primary;
            border-radius: 18px;
        }
    }

}


// ==== 元件 / ====