
.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.6);
  z-index: 99999999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  .loading {
    width: 60px;
    --b: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #008e4b;
    -webkit-mask: repeating-conic-gradient(
        #0000 0deg,
        #000 1deg 70deg,
        #0000 71deg 90deg
      ),
      radial-gradient(
        farthest-side,
        #0000 calc(100% - var(--b) - 1px),
        #000 calc(100% - var(--b))
      );
    -webkit-mask-composite: destination-in;
    mask-composite: intersect;
    animation: l5 1s infinite;
  }
}
@keyframes l5 {
  to {
    transform: rotate(0.5turn);
  }
}
